<h3 mat-dialog-title>
  {{
    (dialogTitle ? 'customer.' + dialogTitle : 'customer.customers') | translate
  }}
</h3>
<div mat-dialog-content>
  <paldesk-customer-filter
    [filterValues]="filter$ | async"
    [countries]="countries$ | async"
    (search)="filterChange($event)"
    (newSearch)="showTable($event)"
  ></paldesk-customer-filter>
  <paldesk-customer-list
    [searchError]="searchError$ | async"
    [loading]="loading$ | async"
    [customers]="customers$ | async"
    [customersCount]="customersCount$ | async"
    [disableSelectionForCompanyExistingInBPM]="
      disableSelectionForCompanyExistingInBPM
    "
    [selectedUser]="selectedPartner"
    (showCustomer)="openCustomerDetails($event)"
    (selectCustomer)="selected.emit($event)"
  ></paldesk-customer-list>
</div>
<mat-dialog-actions
  fxLayoutGap="1rem"
  fxLayoutGap.xs="0"
  fxLayoutAlign.xs="space-between"
  *ngIf="!hideCloseBtn || ((searchPerformed$ | async) && allowCreateCustomer)"
>
  <button *ngIf="!hideCloseBtn" mat-button type="button" mat-dialog-close>
    {{ 'general.close' | translate }}
  </button>
  <button
    *ngIf="(searchPerformed$ | async) && allowCreateCustomer"
    class="new-customer"
    mat-raised-button
    fxFlex="0 0 auto"
    fxFlex.xs="1 1 100%"
    color="white"
    (click)="openEditCustomer('')"
  >
    {{ 'customer.btn_new_customer' | translate }}
  </button>
</mat-dialog-actions>
