import {
  BusinessPartnerResponse,
  CreateBusinessPartnerRequest,
  ProblemDetails,
} from '@data-access/bpm-generated';
import { createAction, props } from '@ngrx/store';
import { CustomerFilter, SearchParameters } from '../model';
import { SearchBusinessPartnersResponse } from '../model/searchBusinessPartnersResponse';

export namespace CustomerActions {
  // actions
  export const LoadCustomerBySapId = createAction(
    '[Customer] LOAD_CUSTOMER_BY_SAP_ID',
    props<{ payload: string }>(),
  );

  export const LoadCustomerByBusinessPartnerNumber = createAction(
    '[Customer] LOAD_CUSTOMER_BY_BUSINESS_PARTNER_NUMBER',
    props<{ payload: string }>(),
  );

  export const LoadCustomerError = createAction(
    '[Customer] LOAD_CUSTOMER_ERROR',
  );
  export const LoadCustomerList = createAction(
    '[Customer] LOAD_CUSTOMER_LIST',
    props<{ payload: SearchParameters }>(),
  );
  export const ChangeFilter = createAction(
    '[Customer] CHANGE_FILTER',
    props<{ payload: CustomerFilter }>(),
  );
  export const CreateCustomer = createAction(
    '[Customer] CREATE_CUSTOMER',
    props<{ payload: CreateBusinessPartnerRequest }>(),
  );

  export const PossibleDuplicatesFound = createAction(
    '[Customer] POSSIBLE_DUPLICATES_FOUND',
    props<{ possibleDuplicates: BusinessPartnerResponse[] }>(),
  );

  // Resets
  export const ResetCustomerEdit = createAction(
    '[Customer] RESET_CUSTOMER_EDIT',
  );
  export const ResetSearchResult = createAction(
    '[Customer] RESET_SEARCH_RESULT',
  );
  export const ResetPossibleDuplicates = createAction(
    '[Customer] RESET_POSSIBLE_DUPLICATES',
  );

  // Results Actions
  export const LoadCustomerSuccess = createAction(
    '[Customer] LOAD_CUSTOMER_SUCCESS',
    props<{ payload?: BusinessPartnerResponse }>(),
  );
  export const LoadCustomerListSuccess = createAction(
    '[Customer] LOAD_CUSTOMER_LIST_SUCCESS',
    props<{ payload: SearchBusinessPartnersResponse }>(),
  );

  export const UpdateCustomerSuccess = createAction(
    '[Customer] UPDATE_CUSTOMER_SUCCESS',
    props<{ payload: BusinessPartnerResponse }>(),
  );

  export const UpdateIndustries = createAction(
    '[Customer] Update_INDUSTRIES',
    props<{ payload: string[] }>(),
  );

  export const CustomerSearchError = createAction(
    '[Customer] SEARCH_CUSTOMER_ERROR',
  );

  export const CustomerCreationError = createAction(
    '[Customer] CUSTOMER_CREATION_ERROR',
    props<{ error: ProblemDetails }>(),
  );
}
