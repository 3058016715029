/**
 * v3 - Business Partner Management
 * Business Partner Management API
 *
 * The version of the OpenAPI document: 3.0
 * Contact: m.kollar@palfinger.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { MyCompanyResponse } from '../model/myCompanyResponse';
// @ts-ignore
import { ProblemDetails } from '../model/problemDetails';
// @ts-ignore
import { SearchCompaniesResponse } from '../model/searchCompaniesResponse';
// @ts-ignore
import { SearchPartnersResponse } from '../model/searchPartnersResponse';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class PartnerNetworkService {

    protected basePath = 'provide_injection_token_for_PartnerNetworkService';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Get detail of logged user Company
     * @param apiVersion The requested API version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public bpmGetMyCompany(apiVersion: '3.0', observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'application/problem+json',}): Observable<MyCompanyResponse>;
    public bpmGetMyCompany(apiVersion: '3.0', observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'application/problem+json',}): Observable<HttpResponse<MyCompanyResponse>>;
    public bpmGetMyCompany(apiVersion: '3.0', observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'application/problem+json',}): Observable<HttpEvent<MyCompanyResponse>>;
    public bpmGetMyCompany(apiVersion: '3.0', observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'application/problem+json',}): Observable<any> {
        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling bpmGetMyCompany.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (apiVersion !== undefined && apiVersion !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>apiVersion, 'api-version');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (OAuth2SecurityScheme) required
        localVarCredential = this.configuration.lookupCredential('OAuth2SecurityScheme');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json',
                'application/problem+json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.get<MyCompanyResponse>(`${this.configuration.basePath}/PartnerNetwork/Companies/My`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Search companies
     * @param countryId 
     * @param companyName 
     * @param apiVersion The requested API version
     * @param zip 
     * @param city 
     * @param accountingGroupsToExclude 
     * @param accountingGroupsToInclude 
     * @param identitificationTypesToInclude 
     * @param partnerTypes 
     * @param searchInBpmFirst 
     * @param getAll 
     * @param includeArchived 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public bpmSearchCompanies(countryId: string, companyName: string, apiVersion: '3.0', zip?: string, city?: string, accountingGroupsToExclude?: Array<string>, accountingGroupsToInclude?: Array<string>, identitificationTypesToInclude?: Array<string>, partnerTypes?: Array<number>, searchInBpmFirst?: boolean, getAll?: boolean, includeArchived?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'application/problem+json',}): Observable<SearchCompaniesResponse>;
    public bpmSearchCompanies(countryId: string, companyName: string, apiVersion: '3.0', zip?: string, city?: string, accountingGroupsToExclude?: Array<string>, accountingGroupsToInclude?: Array<string>, identitificationTypesToInclude?: Array<string>, partnerTypes?: Array<number>, searchInBpmFirst?: boolean, getAll?: boolean, includeArchived?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'application/problem+json',}): Observable<HttpResponse<SearchCompaniesResponse>>;
    public bpmSearchCompanies(countryId: string, companyName: string, apiVersion: '3.0', zip?: string, city?: string, accountingGroupsToExclude?: Array<string>, accountingGroupsToInclude?: Array<string>, identitificationTypesToInclude?: Array<string>, partnerTypes?: Array<number>, searchInBpmFirst?: boolean, getAll?: boolean, includeArchived?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'application/problem+json',}): Observable<HttpEvent<SearchCompaniesResponse>>;
    public bpmSearchCompanies(countryId: string, companyName: string, apiVersion: '3.0', zip?: string, city?: string, accountingGroupsToExclude?: Array<string>, accountingGroupsToInclude?: Array<string>, identitificationTypesToInclude?: Array<string>, partnerTypes?: Array<number>, searchInBpmFirst?: boolean, getAll?: boolean, includeArchived?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'application/problem+json',}): Observable<any> {
        if (countryId === null || countryId === undefined) {
            throw new Error('Required parameter countryId was null or undefined when calling bpmSearchCompanies.');
        }
        if (companyName === null || companyName === undefined) {
            throw new Error('Required parameter companyName was null or undefined when calling bpmSearchCompanies.');
        }
        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling bpmSearchCompanies.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (companyName !== undefined && companyName !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>companyName, 'company_name');
        }
        if (zip !== undefined && zip !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>zip, 'zip');
        }
        if (city !== undefined && city !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>city, 'city');
        }
        if (accountingGroupsToExclude) {
            accountingGroupsToExclude.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'accounting_groups_to_exclude');
            })
        }
        if (accountingGroupsToInclude) {
            accountingGroupsToInclude.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'accounting_groups_to_include');
            })
        }
        if (identitificationTypesToInclude) {
            identitificationTypesToInclude.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'identitification_types_to_include');
            })
        }
        if (partnerTypes) {
            partnerTypes.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'partner_types');
            })
        }
        if (searchInBpmFirst !== undefined && searchInBpmFirst !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>searchInBpmFirst, 'search_in_bpm_first');
        }
        if (getAll !== undefined && getAll !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>getAll, 'get_all');
        }
        if (includeArchived !== undefined && includeArchived !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>includeArchived, 'include_archived');
        }
        if (apiVersion !== undefined && apiVersion !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>apiVersion, 'api-version');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (OAuth2SecurityScheme) required
        localVarCredential = this.configuration.lookupCredential('OAuth2SecurityScheme');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json',
                'application/problem+json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.get<SearchCompaniesResponse>(`${this.configuration.basePath}/PartnerNetwork/Companies/Search/${encodeURIComponent(String(countryId))}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param countryId Partner country code
     * @param companyName Partner company name
     * @param apiVersion The requested API version
     * @param city Partner city
     * @param zip Partner postal code
     * @param partnerType Partner type filter. 1 - AG, 2 - Area, 3 - GeneralAgent, 4 - ServicePartner  If no partner type specified, partner types 3 and 4 are used
     * @param visibleInSalesAndServiceSearch Visible in sales and service search
     * @param includeCompaniesWithoutSapId Include companies without SapId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public bpmSearchPartners(countryId: string, companyName: string, apiVersion: '3.0', city?: string, zip?: string, partnerType?: Array<number>, visibleInSalesAndServiceSearch?: boolean, includeCompaniesWithoutSapId?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'application/problem+json',}): Observable<SearchPartnersResponse>;
    public bpmSearchPartners(countryId: string, companyName: string, apiVersion: '3.0', city?: string, zip?: string, partnerType?: Array<number>, visibleInSalesAndServiceSearch?: boolean, includeCompaniesWithoutSapId?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'application/problem+json',}): Observable<HttpResponse<SearchPartnersResponse>>;
    public bpmSearchPartners(countryId: string, companyName: string, apiVersion: '3.0', city?: string, zip?: string, partnerType?: Array<number>, visibleInSalesAndServiceSearch?: boolean, includeCompaniesWithoutSapId?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'application/problem+json',}): Observable<HttpEvent<SearchPartnersResponse>>;
    public bpmSearchPartners(countryId: string, companyName: string, apiVersion: '3.0', city?: string, zip?: string, partnerType?: Array<number>, visibleInSalesAndServiceSearch?: boolean, includeCompaniesWithoutSapId?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'application/problem+json',}): Observable<any> {
        if (countryId === null || countryId === undefined) {
            throw new Error('Required parameter countryId was null or undefined when calling bpmSearchPartners.');
        }
        if (companyName === null || companyName === undefined) {
            throw new Error('Required parameter companyName was null or undefined when calling bpmSearchPartners.');
        }
        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling bpmSearchPartners.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (companyName !== undefined && companyName !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>companyName, 'company_name');
        }
        if (city !== undefined && city !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>city, 'city');
        }
        if (zip !== undefined && zip !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>zip, 'zip');
        }
        if (partnerType) {
            partnerType.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'partner_type');
            })
        }
        if (visibleInSalesAndServiceSearch !== undefined && visibleInSalesAndServiceSearch !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>visibleInSalesAndServiceSearch, 'visible_in_sales_and_service_search');
        }
        if (includeCompaniesWithoutSapId !== undefined && includeCompaniesWithoutSapId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>includeCompaniesWithoutSapId, 'include_companies_without_sap_id');
        }
        if (apiVersion !== undefined && apiVersion !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>apiVersion, 'api-version');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (OAuth2SecurityScheme) required
        localVarCredential = this.configuration.lookupCredential('OAuth2SecurityScheme');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json',
                'application/problem+json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.get<SearchPartnersResponse>(`${this.configuration.basePath}/PartnerNetwork/Companies/SearchPartners/${encodeURIComponent(String(countryId))}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param countryId Partner country code
     * @param companyName Partner company name
     * @param apiVersion The requested API version
     * @param city Partner city
     * @param zip Partner postal code
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public bpmSearchPartnersForCustomers(countryId: string, companyName: string, apiVersion: '3.0', city?: string, zip?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'application/problem+json',}): Observable<SearchPartnersResponse>;
    public bpmSearchPartnersForCustomers(countryId: string, companyName: string, apiVersion: '3.0', city?: string, zip?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'application/problem+json',}): Observable<HttpResponse<SearchPartnersResponse>>;
    public bpmSearchPartnersForCustomers(countryId: string, companyName: string, apiVersion: '3.0', city?: string, zip?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'application/problem+json',}): Observable<HttpEvent<SearchPartnersResponse>>;
    public bpmSearchPartnersForCustomers(countryId: string, companyName: string, apiVersion: '3.0', city?: string, zip?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'application/problem+json',}): Observable<any> {
        if (countryId === null || countryId === undefined) {
            throw new Error('Required parameter countryId was null or undefined when calling bpmSearchPartnersForCustomers.');
        }
        if (companyName === null || companyName === undefined) {
            throw new Error('Required parameter companyName was null or undefined when calling bpmSearchPartnersForCustomers.');
        }
        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling bpmSearchPartnersForCustomers.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (companyName !== undefined && companyName !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>companyName, 'company_name');
        }
        if (city !== undefined && city !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>city, 'city');
        }
        if (zip !== undefined && zip !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>zip, 'zip');
        }
        if (apiVersion !== undefined && apiVersion !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>apiVersion, 'api-version');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (OAuth2SecurityScheme) required
        localVarCredential = this.configuration.lookupCredential('OAuth2SecurityScheme');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json',
                'application/problem+json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.get<SearchPartnersResponse>(`${this.configuration.basePath}/PartnerNetwork/Companies/SearchPartnersForCustomers/${encodeURIComponent(String(countryId))}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
