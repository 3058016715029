import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import {
  BusinessPartnerResponse,
  CustomerService,
  PartnerNetworkService,
} from '@data-access/bpm-generated';
import { RootActions } from '@features/root-store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { LoadingSpinner } from '@ui-kit/loading-spinner';
import { Observable, of } from 'rxjs';
import {
  catchError,
  filter,
  finalize,
  map,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { SearchBusinessPartnersResponse } from '../model/searchBusinessPartnersResponse';
import { CustomerActions, CustomerSelectors } from '../store';

@Injectable()
export class CustomerEffects {
  private actions$: Actions = inject(Actions);
  private store: Store = inject(Store);

  getCustomerBySapId$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomerActions.LoadCustomerBySapId),
      map((action) => action.payload),
      switchMap((sapNumber) =>
        of(sapNumber).pipe(
          withLatestFrom(this.store.select(CustomerSelectors.byId(sapNumber))),
        ),
      ),
      filter(([, customerInStore]) => !customerInStore),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      mergeMap(([sapNumber, _]) =>
        this.customerService.bpmGetBusinessPartnerDetail(sapNumber, '3.0').pipe(
          map((data) =>
            CustomerActions.LoadCustomerSuccess({
              payload: data,
            }),
          ),
          catchError(() => of(CustomerActions.LoadCustomerError())),
        ),
      ),
    ),
  );

  getCustomerByBusinessPartnerNumber$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomerActions.LoadCustomerByBusinessPartnerNumber),
      map((action) => action.payload),
      switchMap((businessPartnerNumber) =>
        of(businessPartnerNumber).pipe(
          withLatestFrom(
            this.store.select(CustomerSelectors.byId(businessPartnerNumber)),
          ),
        ),
      ),
      filter(([, customerInStore]) => !customerInStore),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      mergeMap(([businessPartnerNumber, _]) =>
        this.customerService
          .bpmGetBusinessPartnerDetailByBPNumber(businessPartnerNumber, '3.0')
          .pipe(
            // eslint-disable-next-line sonarjs/no-identical-functions
            map((data) =>
              CustomerActions.LoadCustomerSuccess({
                payload: data,
              }),
            ),
            catchError(() => of(CustomerActions.LoadCustomerError())),
          ),
      ),
    ),
  );

  getCustomerList$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomerActions.LoadCustomerList),
      withLatestFrom(this.store.select(CustomerSelectors.filter)),
      switchMap(([action, filter_]) =>
        this.partnerNetworkService
          .bpmSearchCompanies(
            filter_.country,
            filter_.companyName,
            '3.0',
            filter_.postalCode,
            filter_.city,
            action.payload.excludeAccountingGroups.length
              ? action.payload.excludeAccountingGroups
              : undefined,
            action.payload.includeOnlyAccountingGroups.length
              ? action.payload.includeOnlyAccountingGroups
              : undefined,
            action.payload.paIdentificationTypesToInclude,
            action.payload.partnerTypes,
            action.payload.searchInBpmFirst,
          )
          .pipe(
            map((data) =>
              CustomerActions.LoadCustomerListSuccess({
                payload: {
                  business_partners: data.companies,
                  count: data.count,
                } as SearchBusinessPartnersResponse,
              }),
            ),
            catchError(() => of(CustomerActions.CustomerSearchError())),
          ),
      ),
    ),
  );

  createCustomer$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomerActions.CreateCustomer),
      tap(() => this.loadingSpinner.show()),
      map((action) => action.payload),
      switchMap((customer) =>
        this.customerService.bpmCreateBusinessPartner('3.0', customer).pipe(
          finalize(() => {
            this.loadingSpinner.hide();
          }),
          map((response) => {
            const customer_: BusinessPartnerResponse = {
              ...customer,
              industry_types: [],
              business_partner_number: '',
              customer_number: response.customer_number,
              created_date: '',
              language: '',
              accounting_group: '',
              is_registered_in_bpm: false,
              is_invited_in_bpm: false,
              identification_types: [],
              is_archived: false,
              taxes: [],
            };
            return CustomerActions.UpdateCustomerSuccess({
              payload: customer_,
            });
          }),
          catchError((error: HttpErrorResponse) => {
            this.store.dispatch(CustomerActions.CustomerCreationError(error));
            return of(CustomerActions.CustomerCreationError(error));
          }),
        ),
      ),
      finalize(() => {
        this.loadingSpinner.hide();
      }),
      catchError((err, caught$) => {
        if (err.status === 409) {
          this.store.dispatch(
            CustomerActions.PossibleDuplicatesFound({
              possibleDuplicates: err.error.data.business_partners,
            }),
          );
        } else {
          this.store.dispatch(RootActions.ShowErrorMessage({ payload: err }));
        }
        return caught$;
      }),
    ),
  );

  constructor(
    private customerService: CustomerService,
    private partnerNetworkService: PartnerNetworkService,
    private loadingSpinner: LoadingSpinner,
  ) {}
}
