export class EnvironmentBase {
  static environment = '';
  static palfinger_api_management = '';
  static palfinger_api_management_subs_key = '';
  static palfinger_app_gateway = '';
  static oidc_clientid = '';
  static oidc_scope = '';

  static domainsForApiManagementSubsKey: string[];
  static LINKS: {
    SECURITY_TOKEN_SERVICE: string;
    POST_LOGOUT_REDIRECT_URI: string;
    PALDESK_BASEPATH: string;
    STATUS_PALDESK_PAGE: string;
  } = {
    SECURITY_TOKEN_SERVICE: '',
    POST_LOGOUT_REDIRECT_URI: '',
    PALDESK_BASEPATH: '',
    STATUS_PALDESK_PAGE: '',
  };

  static identityServerAuthConf: {
    whitelistedDomains: string[];
    whitelistedRoutes: string[];
  } = {
    whitelistedDomains: [],
    whitelistedRoutes: [],
  };
}
