import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  signal,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  BusinessPartnerResponse,
  CountryResponse,
  CreateBusinessPartnerRequest,
  IndustryTypeRequest,
  ProblemDetails,
} from '@data-access/bpm-generated';
import { CountryFacade } from '@features/root-store';
import { Store } from '@ngrx/store';
import { nameofFactory } from '@utils/name-of';
import { Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { SAP_CUSTOMER_CONFIG, SapCustomerConfig } from '../config';
import { IndustryComponent } from '../industry/industry.component';
import { CustomerActions, CustomerSelectors } from '../shared/store';

const nameof = nameofFactory<BusinessPartnerResponse>();

@Component({
  selector: 'paldesk-edit-customer',
  templateUrl: './edit-customer.component.html',
  styleUrls: ['./edit-customer.component.scss'],
})
export class EditCustomerComponent implements OnInit, OnDestroy {
  @ViewChild(IndustryComponent)
  industryComponent: IndustryComponent;

  @ViewChild('duplicateCompaniesParagraph')
  duplicateCompaniesParagraph: ElementRef;

  @Output()
  selectFromDuplicates: EventEmitter<BusinessPartnerResponse> =
    new EventEmitter<BusinessPartnerResponse>();

  possibleDuplicates$: Observable<BusinessPartnerResponse[] | undefined>;
  customer: BusinessPartnerResponse | null;
  customerCreated$: Observable<boolean>;
  form: UntypedFormGroup;
  customerIndustries: string[] = [];
  readonly nameof = nameof;
  countries$: Observable<CountryResponse[] | undefined>;
  isPhoneNumberOptional: boolean;
  isIndustryOptional: boolean;
  allMarkedAsTouched = false;
  confirmDisabled = false;
  errorMessage = signal<string | null>(''); // Signal for error message
  errorObservable$: Observable<ProblemDetails | null>; // Set the initial value to null to handle the absence of errors
  private destroy$ = new Subject<void>();

  constructor(
    @Optional() @Inject(SAP_CUSTOMER_CONFIG) private config: SapCustomerConfig,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store<any>,
    public fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<EditCustomerComponent>,
    private el: ElementRef,
    private countryFacade: CountryFacade,
  ) {
    this.isPhoneNumberOptional =
      this.config?.edit_customer_phone_number_optional;
    this.isIndustryOptional = this.config?.edit_customer_industry_optional;
    this.form = fb.group({
      // name
      company_name: [
        { value: '', disabled: this.data.customerNumber },
        Validators.required,
      ],
      [nameof('company_name2')]: [
        { value: '', disabled: this.data.customerNumber },
      ],
      // adress
      country_code: [
        { value: '', disabled: this.data.customerNumber },
        {
          validators: Validators.required,
        },
      ],
      city: ['', Validators.required],
      postal_code: ['', Validators.required],
      street_name: ['', Validators.required],
      house_number: ['', Validators.required],
      // contact
      [nameof('email')]: ['', Validators.email],
      [nameof('fax')]: [''],
      [nameof('mobile_phone_number')]: [''],
      [nameof('phone_number')]: this.isPhoneNumberOptional
        ? ['']
        : ['', Validators.required],
      [nameof('url')]: [''],
      // meta
      [nameof('industry_types')]: [],
    });

    if (!this.isIndustryOptional) {
      const industryCode = this.form.get('industry_types');
      if (industryCode) industryCode.setValidators([Validators.required]);
    }
    this.customerCreated$ = this.store.select(CustomerSelectors.created);
  }

  ngOnInit() {
    this.customer = {} as BusinessPartnerResponse;
    this.customer.customer_number = '';
    this.customerIndustries.push('');

    this.store
      .select(CustomerSelectors.selectedIndustries)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.updateIndustries(data);
      });

    this.errorObservable$ = this.store.select(
      CustomerSelectors.creationError,
    ) as Observable<ProblemDetails | null>;

    this.countries$ = this.countryFacade.getCountries();

    this.store
      .select(CustomerSelectors.updated)
      .pipe(takeUntil(this.destroy$))
      .subscribe((updated) => {
        if (updated) {
          this.dialogRef.close();
        }
      });
    this.possibleDuplicates$ = this.store
      .select(CustomerSelectors.possibleDuplicates)
      .pipe(
        tap((possibleDuplicates) => {
          if (possibleDuplicates?.length) {
            this.confirmDisabled = true;
            setTimeout(() => {
              this.duplicateCompaniesParagraph.nativeElement.scrollIntoView({
                behavior: 'smooth',
              });
            }, 0);
          } else {
            this.confirmDisabled = false;
          }
        }),
      );
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  checkIfValueIsString(value: string | IndustryTypeRequest) {
    return typeof value === 'string';
  }

  confirmChanges() {
    if (this.form.dirty) {
      if (this.canSaveForm) {
        const formValue = this.form.value;
        Object.keys(formValue).forEach((key: string) => {
          if (this.checkIfValueIsString(formValue[key])) {
            formValue[key] = formValue[key].trim();
          }
        });
        const customer: CreateBusinessPartnerRequest = {
          ...this.customer,
          ...formValue,
        };
        this.store.dispatch(
          CustomerActions.CreateCustomer({ payload: customer }),
        );
      } else {
        this.handleInvalidForm();
      }
    }
  }

  /**
   * If form is not ready to submit, highlight all required fields, so user can see what's missing
   */
  private handleInvalidForm() {
    Object.keys(this.form.controls).forEach((field) => {
      const control = this.form.get(field);
      if (control) control.markAsTouched({ onlySelf: true });
    });
    this.allMarkedAsTouched = true;
    /**
     * Focus the first invalid form field
     */
    const invalidElements =
      this.el.nativeElement.querySelectorAll('input.ng-invalid');
    if (invalidElements.length) {
      invalidElements[0].focus();
    }
  }

  private updateIndustries = (industries: string[] = []): void => {
    let industryTypes: IndustryTypeRequest[] = [];
    industries.forEach((item: string) => {
      industryTypes = [
        ...industryTypes,
        ...[
          {
            sector: item.slice(2, item.length),
            system_type: item.slice(0, 2),
          },
        ],
      ];
    });
    this.form.patchValue({
      industry_types: industryTypes,
    });
    this.form.markAsDirty();
  };

  private get canSaveForm(): boolean {
    return (
      this.form.valid &&
      (!this.industryComponent || this.industryComponent.valid)
    );
  }
}
