<h3 matDialogTitle>{{ 'customer.customer' | translate }}</h3>
<mat-dialog-content>
  <ng-container
    *ngIf="customer?.customer_number === data.customerNumber; else loading"
  >
    <form [formGroup]="form" novalidate>
      <div
        fxLayout="row"
        fxLayoutAlign="space-between"
        fxLayoutGap="1rem"
        fxLayout.xs="column"
        fxLayoutGap.xs="0"
      >
        <div fxFlex>
          <mat-form-field class="full-width">
            <mat-label>{{ 'customer.company_name' | translate }}</mat-label>
            <input
              matInput
              maxlength="35"
              [formControlName]="nameof('company_name')"
              required
            />
          </mat-form-field>
        </div>
        <div fxFlex>
          <mat-form-field class="full-width">
            <mat-label>{{ 'customer.company_name' | translate }} 2</mat-label>
            <input
              matInput
              maxlength="35"
              [formControlName]="nameof('company_name2')"
            />
          </mat-form-field>
        </div>
      </div>
      <div
        fxLayout="row"
        fxLayoutAlign="space-between"
        fxLayoutGap="1rem"
        fxLayout.xs="column"
        fxLayoutGap.xs="0"
      >
        <div fxFlex>
          <mat-form-field class="full-width">
            <mat-label>{{ 'customer.country' | translate }}</mat-label>
            <mat-select
              [formControlName]="nameof('country_code')"
              name="country"
              required
            >
              <mat-option
                *ngFor="let country of countries$ | async"
                [value]="country.country_code"
                >{{ country.name }}</mat-option
              >
            </mat-select>

            <mat-progress-spinner
              *ngIf="(countries$ | async) === undefined"
              matTextSuffix
              [mode]="'indeterminate'"
              color="accent"
              [diameter]="14"
            ></mat-progress-spinner>
          </mat-form-field>
        </div>
      </div>
      <div
        fxLayout="row"
        fxLayoutAlign="space-between"
        fxLayoutGap="1rem"
        fxLayout.xs="row wrap"
        fxLayoutGap.xs="0"
      >
        <div
          fxFlex
          fxLayoutAlign="space-between"
          fxLayoutGap="1rem"
          fxFlex.xs="100%"
          fxLayout.xs="row wrap"
          fxLayoutGap.xs="0"
        >
          <div fxFlex fxFlex.xs="100%">
            <mat-form-field class="full-width">
              <mat-label>{{ 'customer.street' | translate }}</mat-label>
              <input
                matInput
                maxlength="35"
                [formControlName]="nameof('street_name')"
                required
              />
            </mat-form-field>
          </div>
          <div fxFlex="90px" fxFlex.xs="100%">
            <mat-form-field class="full-width">
              <mat-label>{{ 'customer.house_number' | translate }}</mat-label>
              <input
                matInput
                maxlength="10"
                [formControlName]="nameof('house_number')"
                required
              />
            </mat-form-field>
          </div>
        </div>
        <div
          fxFlex
          fxLayoutAlign="space-between"
          fxLayoutGap="1rem"
          fxFlex.xs="100%"
          fxLayout.xs="row wrap"
          fxLayoutGap.xs="0"
        >
          <div fxFlex fxFlex.xs="100%">
            <mat-form-field class="full-width">
              <mat-label>{{ 'customer.city' | translate }}</mat-label>
              <input
                matInput
                maxlength="35"
                [formControlName]="nameof('city')"
                required
              />
            </mat-form-field>
          </div>
          <div fxFlex="100px" fxFlex.xs="100%">
            <mat-form-field class="full-width">
              <mat-label>{{ 'customer.zip' | translate }}</mat-label>
              <input
                matInput
                maxlength="10"
                [formControlName]="nameof('postal_code')"
                required
              />
            </mat-form-field>
          </div>
        </div>
      </div>
      <div
        fxLayout="row"
        fxLayoutAlign="space-between"
        fxLayoutGap="1rem"
        fxLayout.xs="column"
        fxLayoutGap.xs="0"
      >
        <div fxFlex>
          <mat-form-field class="full-width">
            <mat-label>{{ 'customer.email' | translate }}</mat-label>
            <input
              matInput
              maxlength="50"
              [formControlName]="nameof('email')"
            />
          </mat-form-field>
        </div>
        <div fxFlex>
          <mat-form-field class="full-width">
            <mat-label>{{ 'customer.website' | translate }}</mat-label>
            <input matInput maxlength="30" [formControlName]="nameof('url')" />
          </mat-form-field>
        </div>
      </div>
      <div
        fxLayout="row"
        fxLayoutAlign="space-between"
        fxLayoutGap="1rem"
        fxLayout.xs="column"
        fxLayoutGap.xs="0"
      >
        <div fxFlex>
          <mat-form-field class="full-width">
            <mat-label>{{ 'customer.phone' | translate }}</mat-label>
            <input
              matInput
              maxlength="16"
              [formControlName]="nameof('phone_number')"
              [required]="!isPhoneNumberOptional"
            />
          </mat-form-field>
        </div>
        <div fxFlex>
          <mat-form-field class="full-width">
            <mat-label>{{ 'customer.mobile' | translate }}</mat-label>
            <input
              matInput
              maxlength="30"
              [formControlName]="nameof('mobile_phone_number')"
            />
          </mat-form-field>
        </div>
        <div fxFlex>
          <mat-form-field class="full-width">
            <mat-label>{{ 'customer.fax' | translate }}</mat-label>
            <input matInput maxlength="31" [formControlName]="nameof('fax')" />
          </mat-form-field>
        </div>
      </div>
      <div
        *ngIf="errorObservable$ | async as errorResponse"
        class="error-message"
      >
        {{ errorResponse?.title }}
      </div>
      <paldesk-industry
        [industries]="customerIndustries"
        [touched]="allMarkedAsTouched"
      ></paldesk-industry>
    </form>
  </ng-container>
  <ng-template #loading>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </ng-template>
  <div *ngIf="possibleDuplicates$ | async as possibleDuplicates">
    <p class="error-message" #duplicateCompaniesParagraph>
      {{ 'customer.possible_duplicates_found' | translate }}
      <a href="mailto:paldesk@palfinger.com"> PALDESK support</a>
    </p>
    <paldesk-customer-list
      [customers]="possibleDuplicates"
      (selectCustomer)="selectFromDuplicates.emit($event)"
    ></paldesk-customer-list>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button matDialogClose>
    {{ 'customer.close' | translate }}
  </button>
  <button
    mat-raised-button
    *ngIf="(customerCreated$ | async) === false"
    color="primary"
    (click)="confirmChanges()"
    [disabled]="confirmDisabled"
  >
    {{ 'customer.confirm' | translate }}
  </button>
</mat-dialog-actions>
