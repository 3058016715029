import { Component } from '@angular/core';
import { Menu } from '@design-system/feature/app-wrapper-v2';

@Component({
  selector: 'sp-shop-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  menu: Menu = {
    title: 'start_page.sparepart_shop_SP',
    titleIcon: 'shopping_cart',
    navigation: [
      {
        routerLink: '/create-order',
        title: 'sidebar.create_order',
        icon: 'add',
      },
      {
        routerLink: '/order-history',
        title: 'sidebar.order_history',
        icon: 'history',
      },
    ],
  };
}
