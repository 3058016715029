import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import {
  DsSnackbarService,
  DsSnackbarType,
} from '@design-system/feature/snackbar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { OrderService } from '@paldesk/shared-lib/data-access/sparepart-shop-generated';
import { saveAs } from 'file-saver';
import { of } from 'rxjs';
import {
  catchError,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { OrderHistoryActions } from './order-history.actions';
import { OrderHistorySelectors } from './order-history.selectors';
@Injectable()
export class OrderHistoryEffects {
  private readonly _actions: Actions = inject(Actions);
  private readonly _store: Store<any> = inject(Store);

  loadOrder$ = createEffect(() =>
    this._actions.pipe(
      ofType(OrderHistoryActions.LoadOrderHistoryById),
      map((action) => action.payload),
      switchMap((payload) =>
        this._orderService.getOrderHistoryById(payload).pipe(
          map((order) =>
            OrderHistoryActions.LoadOrderHistoryByIdSuccess({
              payload: order,
            }),
          ),
          catchError((err: HttpErrorResponse) => {
            this._snackbar.queue(
              this._translateService.instant(
                'order_history.error.order_history_detail_not_loaded',
              ),
              {
                type: DsSnackbarType.Error,
              },
            );
            return of(
              OrderHistoryActions.LoadOrderHistoryByIdError({
                payload: err.error,
              }),
            );
          }),
        ),
      ),
    ),
  );

  filterUpdates$ = createEffect(() =>
    this._actions.pipe(
      ofType(
        OrderHistoryActions.ClearFilter,
        OrderHistoryActions.FilterChange,
        OrderHistoryActions.PageChange,
      ),
      map(() => OrderHistoryActions.SearchOrderHistory()),
    ),
  );

  searchOrders$ = createEffect(() =>
    this._actions.pipe(
      ofType(OrderHistoryActions.SearchOrderHistory),
      withLatestFrom(
        this._store.select(OrderHistorySelectors.pagination),
        this._store.select(OrderHistorySelectors.filters),
      ),
      switchMap(([, pagination, filters]) =>
        this._orderService
          .searchOrderHistory(
            filters?.SearchTerm || undefined,
            pagination.pageIndex,
            pagination.pageSize,
          )
          .pipe(
            map((data) =>
              OrderHistoryActions.SearchOrderHistorySuccess({ payload: data }),
            ),
            catchError((err: HttpErrorResponse) => {
              this._snackbar.queue(
                this._translateService.instant(
                  'order_history.error.order_history_not_loaded',
                ),
                {
                  type: DsSnackbarType.Error,
                },
              );
              return of(
                OrderHistoryActions.SearchOrderHistoryError({
                  payload: err.error,
                }),
              );
            }),
          ),
      ),
    ),
  );
  searchOrdersExport$ = createEffect(() =>
    this._actions.pipe(
      ofType(OrderHistoryActions.ExportOrderHistory),
      withLatestFrom(this._store.select(OrderHistorySelectors.filters)),
      switchMap(([, filters]) =>
        this._orderService
          .exportSearchOrderHistory(filters?.SearchTerm || undefined)
          .pipe(
            map((data) =>
              OrderHistoryActions.ExportOrderHistorySuccess({
                payload: { data: data, name: 'Orders.csv' },
              }),
            ),
            catchError((error: HttpErrorResponse) => [
              OrderHistoryActions.ExportOrderHistoryError(error.error),
            ]),
          ),
      ),
    ),
  );

  exportOrder$ = createEffect(() =>
    this._actions.pipe(
      ofType(OrderHistoryActions.ExportOrderHistoryDetail),
      map((action) => action.payload),
      switchMap((payload) =>
        this._orderService.exportOrderHistoryById(payload).pipe(
          map((data) =>
            OrderHistoryActions.ExportOrderHistoryDetailSuccess({
              payload: { data: data, name: 'Orders' + payload + '.csv' },
            }),
          ),
          catchError((error: HttpErrorResponse) => [
            OrderHistoryActions.ExportOrderHistoryDetailError(error.error),
          ]),
        ),
      ),
    ),
  );

  exportOrdersSuccess$ = createEffect(
    () =>
      this._actions.pipe(
        ofType(OrderHistoryActions.ExportOrderHistorySuccess),
        map((a) => a.payload),
        tap((payload) => saveAs(payload.data, payload.name)),
      ),
    { dispatch: false },
  );

  exportOrdersError$ = createEffect(
    () =>
      this._actions.pipe(
        ofType(OrderHistoryActions.ExportOrderHistoryError),
        switchMap(() =>
          this._snackbar.queue(
            this._translateService.instant('order_history.export_failed'),
            {
              type: DsSnackbarType.Error,
            },
          ),
        ),
      ),
    { dispatch: false },
  );

  exportOrderDetailSuccess$ = createEffect(
    () =>
      this._actions.pipe(
        ofType(OrderHistoryActions.ExportOrderHistoryDetailSuccess),
        map((a) => a.payload),
        tap((payload) => saveAs(payload.data, payload.name)),
      ),
    { dispatch: false },
  );

  exportOrderDetailError$ = createEffect(
    () =>
      this._actions.pipe(
        ofType(OrderHistoryActions.ExportOrderHistoryDetailError),
        // eslint-disable-next-line sonarjs/no-identical-functions
        switchMap(() =>
          this._snackbar.queue(
            this._translateService.instant('order_history.export_failed'),
            {
              type: DsSnackbarType.Error,
            },
          ),
        ),
      ),
    { dispatch: false },
  );
  constructor(
    private readonly _orderService: OrderService,
    private readonly _snackbar: DsSnackbarService,
    private readonly _translateService: TranslateService,
  ) {}
}
